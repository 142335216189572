<template>
    <div class="container-fluid">
        
        <div class="block">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>標題(中文)</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="請輸入標題(中文)"
                            maxlength="50"
                            v-model.trim="restaurantInfo.TitleChinese"
                        />
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>標題(英文)</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="請輸入標題(英文)"
                            maxlength="100"
                            v-model.trim="restaurantInfo.TitleEnglish"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="block">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>問卷說明(中文)</label>
                        <textarea
                            cols="30"
                            rows="3"
                            class="form-control"
                            placeholder="請輸入問卷說明(中文)"
                            maxlength="200"
                            v-model.trim="restaurantInfo.DescriptionChinese"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>問卷說明(英文)</label>
                        <textarea
                            cols="30"
                            rows="3"
                            class="form-control"
                            placeholder="請輸入問卷說明(英文)"
                            maxlength="400"
                            v-model.trim="restaurantInfo.DescriptionEnglish"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="block">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>填寫完整頁面說明(中文)</label>
                        <textarea
                            cols="30"
                            rows="3"
                            class="form-control"
                            placeholder="填寫完整頁面說明(中文)"
                            maxlength="200"
                            v-model.trim="restaurantInfo.CompletedChinese"
                        />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>填寫完整頁面說明(英文)</label>
                        <textarea
                            cols="30"
                            rows="3"
                            class="form-control"
                            placeholder="填寫完整頁面說明(英文)"
                            maxlength="400"
                            v-model.trim="restaurantInfo.CompletedEnglish"
                        />
                    </div>
                </div>
            </div>
        </div>
        

        <div class="card search-card">
            <div class="card-body">
                <div class="form-row">
                    <div class="col-sm-3 col-md-2">
                        <div class="form-group">
                        <label>棟別</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="請輸入關鍵字"
                            @keydown.stop.prevent.enter="getBulidingPages(1, buildingTableInfo.per_page)"
                            v-model.trim="buildingKeyword"
                        />
                        </div>
                    </div>

                    <div class="col-sm-3 col-md-3">
                        <button
                            type="button"
                            class="btn btn-primary btn-search"
                            style="margin-top: 30px; margin-left: 10px"
                            @click.prevent="getBulidingPages(1, buildingTableInfo.per_page)"
                        >
                        {{ i18n("Buttons.Search") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-create"
                            style="margin-top: 30px; margin-left: 10px"
                            data-toggle="modal"
                            data-target="#buildingModal"
                            @click="commonModal(true)"
                        >
                        新增棟別
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="yahome-table-wrap">
            <VueBootstrap4Table :rows="buildingRows" :columns="buildingColumns" :config="config" :classes="classes" @on-change-query="onChangeBuliding" :total-rows="buildingTotal">

                <!-- <template slot="edit" slot-scope="props">
                <button
                    class="btn btn-info btn-sm"
                    style="margin-right: 0.5rem"
                    data-toggle="modal"
                    data-target="#buildingModal"
                    @click="commonModal(false, props.row)"
                >
                    {{ i18n("Buttons.Edit") }}
                </button>
                </template> -->

                <template slot="delete" slot-scope="props">
                <button
                    class="btn btn-danger btn-delete btn-sm"
                    data-toggle="modal"
                    data-target="#delBuildingModal"
                    @click="commonModal(false, props.row)"
                >
                    {{ i18n("Buttons.Delete") }}
                </button>
                </template>
                <template slot="empty-results"> 暫無資料 </template>
            </VueBootstrap4Table>
        </div>

        <div class="card search-card">
            <div class="card-body">
                <div class="form-row">
                    <div class="col-sm-3 col-md-2">
                        <div class="form-group">
                        <label>區域</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="請輸入關鍵字"
                            @keydown.stop.prevent.enter="getAreaPages(1, areaTableInfo.per_page)"
                            v-model.trim="areaKeyword"
                        />
                        </div>
                    </div>

                    <div class="col-sm-3 col-md-3">
                        <button
                            type="button"
                            class="btn btn-primary btn-search"
                            style="margin-top: 30px; margin-left: 10px"
                            @click.prevent="getAreaPages(1, areaTableInfo.per_page)"
                        >
                        {{ i18n("Buttons.Search") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-create"
                            style="margin-top: 30px; margin-left: 10px"
                            data-toggle="modal"
                            data-target="#areaModal"
                            @click="commonModal(true)"
                        >
                        新增區域
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="yahome-table-wrap">
            <VueBootstrap4Table :rows="areaRows" :columns="areaColumns" :config="config" :classes="classes" @on-change-query="onChangeArea" :total-rows="areaTotal">

                <!-- <template slot="edit" slot-scope="props">
                <button
                    class="btn btn-info btn-sm"
                    style="margin-right: 0.5rem"
                    data-toggle="modal"
                    data-target="#areaModal"
                    @click="commonModal(false, props.row)"
                >
                    {{ i18n("Buttons.Edit") }}
                </button>
                </template> -->

                <template slot="qrcode" slot-scope="props">
                <button
                    class="btn btn-info btn-sm"
                    style="margin-right: 0.5rem"
                    data-toggle="modal"
                    data-target="#qrcodeModal"
                    @click="showQrcode(props.row)"
                >
                    產生QRCode
                </button>
                </template>

                <template slot="delete" slot-scope="props">
                <button
                    class="btn btn-danger btn-delete btn-sm"
                    data-toggle="modal"
                    data-target="#delAreaModal"
                    @click="commonModal(false, props.row)"
                >
                    {{ i18n("Buttons.Delete") }}
                </button>
                </template>
                <template slot="empty-results"> 暫無資料 </template>
            </VueBootstrap4Table>
        </div>

        <div class="card search-card">
            <div class="card-body">
                <div class="form-row">
                    <div class="col-sm-3 col-md-2">
                        <div class="form-group">
                        <label>座位號碼</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="請輸入關鍵字"
                            @keydown.stop.prevent.enter="getRestaurantSeatNoPages(1, seatTableInfo.per_page)"
                            v-model.trim="seatKeyword"
                        />
                        </div>
                    </div>

                    <div class="col-sm-3 col-md-3">
                        <button
                            type="button"
                            class="btn btn-primary btn-search"
                            style="margin-top: 30px; margin-left: 10px"
                            @click.prevent="getRestaurantSeatNoPages(1, seatTableInfo.per_page)"
                        >
                        {{ i18n("Buttons.Search") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-create"
                            style="margin-top: 30px; margin-left: 10px"
                            data-toggle="modal"
                            data-target="#seatModal"
                            @click="commonModal(true)"
                        >
                        新增座位號碼
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="yahome-table-wrap">
            <VueBootstrap4Table :rows="seatRows" :columns="seatColumns" :config="config" :classes="classes" @on-change-query="onChangeSeat" :total-rows="seatTotal">

                <!-- <template slot="edit" slot-scope="props">
                <button
                    class="btn btn-info btn-sm"
                    style="margin-right: 0.5rem"
                    data-toggle="modal"
                    data-target="#seatModal"
                    @click="commonModal(false, props.row)"
                >
                    {{ i18n("Buttons.Edit") }}
                </button>
                </template> -->

                <template slot="delete" slot-scope="props">
                <button
                    class="btn btn-danger btn-delete btn-sm"
                    data-toggle="modal"
                    data-target="#delSeatModal"
                    @click="commonModal(false, props.row)"
                >
                    {{ i18n("Buttons.Delete") }}
                </button>
                </template>
                <template slot="empty-results"> 暫無資料 </template>
            </VueBootstrap4Table>
        </div>

        <!-- fiexed button -->
        <button class="btn btn-success" id="save" @click.prevent="saveRestaurantCnrDesign">
            <!-- <i class="fa fa-angle-up"></i> -->
            儲存
        </button>

        <!-- 棟別 Modal -->
        <div
            id="buildingModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            data-backdrop="static"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <ValidationObserver
                    ref="building"
                    class="modal-content"
                    v-slot="{ handleSubmit, reset }"
                >
                <form @reset.prevent="reset">
                    <div class="modal-header">
                        <span v-if="isAdd">新增棟別</span>
                        <span v-else>編輯棟別</span>
                    </div>
                    <div class="modal-body">
                        <div class="field">
                            <ValidationProvider
                                tag="div"
                                :rules="`my_not:${nowItem.Name}|required`"
                                v-slot="{ errors }"
                            >
                                <label>
                                    <span class="mark">*</span>棟別(中文)
                                    <!-- <span class="mark">(不可重複)</span> -->
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    maxlength="50"
                                    v-model="item.BuildingChinese"
                                />
                                <span v-if="errors[0]" class="text-danger">
                                {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </div>

                        <div class="field">
                            <ValidationProvider
                                tag="div"
                                :rules="`my_not:${nowItem.Name}|required`"
                                v-slot="{ errors }"
                            >
                                <label>
                                    <span class="mark">*</span>棟別(英文)
                                    <!-- <span class="mark">(不可重複)</span> -->
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    maxlength="50"
                                    v-model="item.BuildingEnglish"
                                />
                                <span v-if="errors[0]" class="text-danger">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="reset"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            {{ i18n("Buttons.Cancel") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-success"
                            @click="handleSubmit(saveBuilding)"
                        >
                            {{ i18n("Buttons.Save") }}
                        </button>
                    </div>
                </form>
                </ValidationObserver>
            </div>
        </div>

        <!-- 刪除棟別 Modal -->
        <div
            id="delBuildingModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            data-backdrop="static"
        >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">刪除棟別</div>
                <div class="modal-body">
                    <!-- 確認刪除 -->
                    刪除此筆資料會連動區域及座位號碼，且刪除後不能再新增同名
                    <span style="font-weight: bolder; color: red">[棟別]</span>
                    ，請確認是否刪除
                    <span style="font-weight: bolder; color: red">
                    {{ item.BuildingChinese }} </span
                    >這筆資料?
                </div>
                <div class="modal-footer">
                    <button type="reset" class="btn btn-secondary" data-dismiss="modal">
                    {{ i18n("Buttons.Cancel") }}
                    </button>
                    <button type="button" class="btn btn-danger" @click="delBuliding">
                    {{ i18n("Buttons.Delete") }}
                    </button>
                </div>
            </div>
        </div>
        </div>

        <!-- 區域 Modal -->
        <div
            id="areaModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            data-backdrop="static"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <ValidationObserver
                    ref="area"
                    class="modal-content"
                    v-slot="{ handleSubmit, reset }"
                >
                    <form @reset.prevent="reset">
                        <div class="modal-header">
                            <span v-if="isAdd">新增區域</span>
                            <span v-else>編輯區域</span>
                        </div>
                        <div class="modal-body">
                            <div class="field">
                                <ValidationProvider
                                    tag="div"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <label>
                                        <span class="mark">*</span>棟別
                                        <!-- <span class="mark">(不可重複)</span> -->
                                    </label>
                                    <v-select
                                        :options="buildingOptions"
                                        label="BuildingChinese"
                                        placeholder="請選擇"
                                        :reduce="(buildingOption) => buildingOption.Guid"
                                        v-model="item.BuildingGuid"
                                    >
                                        <!-- <template slot-scope="position">
                                            {{ position.BuildingChinese }}
                                        </template> -->
                                    </v-select>
                                    <span v-if="errors[0]" class="text-danger">
                                    {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>

                            <div class="field">
                                <ValidationProvider
                                    tag="div"
                                    :rules="`my_not:${nowItem.Name}|required`"
                                    v-slot="{ errors }"
                                >
                                    <label>
                                        <span class="mark">*</span>區域(中文)
                                        <!-- <span class="mark">(不可重複)</span> -->
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        maxlength="50"
                                        v-model="item.AreaChinese"
                                    />
                                    <span v-if="errors[0]" class="text-danger">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>

                            <div class="field">
                                <ValidationProvider
                                    tag="div"
                                    :rules="`my_not:${nowItem.Name}|required`"
                                    v-slot="{ errors }"
                                >
                                    <label>
                                        <span class="mark">*</span>區域(英文)
                                        <!-- <span class="mark">(不可重複)</span> -->
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        maxlength="50"
                                        v-model="item.AreaEnglish"
                                    />
                                    <span v-if="errors[0]" class="text-danger">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="reset"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                {{ i18n("Buttons.Cancel") }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-success"
                                @click="handleSubmit(saveArea)"
                            >
                                {{ i18n("Buttons.Save") }}
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>

        <!-- 刪除區域 Modal -->
        <div
            id="delAreaModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            data-backdrop="static"
        >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">刪除區域</div>
                <div class="modal-body">
                    刪除此筆資料會連動座位號碼，且刪除後在此棟別不能再新增同名
                    <span style="font-weight: bolder; color: red">[區域]</span>
                    ，請確認是否刪除
                    <span style="font-weight: bolder; color: red">
                    {{ item.AreaChinese }} </span
                    >這筆資料?
                </div>
                <div class="modal-footer">
                    <button type="reset" class="btn btn-secondary" data-dismiss="modal">
                    {{ i18n("Buttons.Cancel") }}
                    </button>
                    <button type="button" class="btn btn-danger" @click="delArea">
                    {{ i18n("Buttons.Delete") }}
                    </button>
                </div>
            </div>
        </div>
        </div>

        <!-- 座位號碼 Modal -->
        <div
            id="seatModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            data-backdrop="static"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <ValidationObserver
                    ref="seat"
                    class="modal-content"
                    v-slot="{ handleSubmit, reset }"
                >
                    <form @reset.prevent="reset">
                        <div class="modal-header">
                            <span v-if="isAdd">新增座位號碼</span>
                            <span v-else>編輯座位號碼</span>
                        </div>
                        <div class="modal-body">
                            <div class="field">
                                <ValidationProvider
                                    tag="div"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <label>
                                        <span class="mark">*</span>棟別
                                        <!-- <span class="mark">(不可重複)</span> -->
                                    </label>
                                    <v-select
                                        :options="buildingOptions"
                                        label="BuildingChinese"
                                        placeholder="請選擇"
                                        :reduce="(buildingOption) => buildingOption.Guid"
                                        v-model="item.BuildingGuid"
                                    >
                                        <!-- <template slot-scope="position">
                                            {{ position.BuildingChinese }}
                                        </template> -->
                                    </v-select>
                                    <span v-if="errors[0]" class="text-danger">
                                    {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>

                            <div class="field">
                                <ValidationProvider
                                    tag="div"
                                    rules="required"
                                    v-slot="{ errors }"
                                >
                                    <label>
                                        <span class="mark">*</span>區域
                                        <!-- <span class="mark">(不可重複)</span> -->
                                    </label>
                                    <v-select
                                        :options="areaOptions"
                                        label="AreaChinese"
                                        placeholder="請選擇"
                                        :reduce="(areaOption) => areaOption.Guid"
                                        v-model="item.AreaGuid"
                                    >
                                    </v-select>
                                    <span v-if="errors[0]" class="text-danger">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>

                            <div class="field">
                                <ValidationProvider
                                    tag="div"
                                    :rules="`my_not:${nowItem.Name}|required`"
                                    v-slot="{ errors }"
                                >
                                    <label>
                                        <span class="mark">*</span>座位號碼
                                        <!-- <span class="mark">(不可重複)</span> -->
                                    </label>
                                    <input
                                        type="number"
                                        class="form-control"
                                        v-model="item.SeatNo"
                                        maxlength="50"
                                        oninput="value=this.value.replace(/\D+/g,'')"
                                    />
                                    <span v-if="errors[0]" class="text-danger">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="reset"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                {{ i18n("Buttons.Cancel") }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-success"
                                @click="handleSubmit(saveSeat)"
                            >
                                {{ i18n("Buttons.Save") }}
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>

        <!-- 刪除座位號碼 Modal -->
        <div
            id="delSeatModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            data-backdrop="static"
        >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">刪除座位號碼</div>
                <div class="modal-body">
                    刪除後在此棟別區域不能再新增同名
                    <span style="font-weight: bolder; color: red">[座位號碼]</span>，
                    是否確認刪除
                    <span style="font-weight: bolder; color: red">
                    {{ item.BuildingChinese }} {{ item.AreaChinese }} {{ item.SeatNo }}</span
                    >?
                </div>
                <div class="modal-footer">
                    <button type="reset" class="btn btn-secondary" data-dismiss="modal">
                    {{ i18n("Buttons.Cancel") }}
                    </button>
                    <button type="button" class="btn btn-danger" @click="delSeat">
                    {{ i18n("Buttons.Delete") }}
                    </button>
                </div>
            </div>
        </div>
        </div>

        <!--顯示Qrcode-->
        <div id="qrcodeModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div
                    style="display:block; text-align:center; color:#FFF; padding:20px;"
                >
                    QRcode
                </div>
                    <div class="modal-body">
                        <template>
                            <qrcode-vue
                                id="qrcode"
                                :value="QRcode"
                                :size="size"
                                class="qrcode-wrap"
                                level="H"
                            />
                        </template>
                        <div>
                            <!-- <span style="color: white;font-size: .8em;">{{ QRcode }}</span> height: 0; -->
                            <input
                                v-if="QRcode"
                                id="copyText"
                                type="text"
                                size="5"
                                style="opacity: 0;width: 100%;"
                                v-model="QRcode"
                            />
                        </div>
                        <div style="margin-top: 12px;display: flex;justify-content: center;">
                            <button
                                class="btn btn-primary mr-2"
                                @click.prevent="downloadQRCode(`${QRCodeItem.BuildingChinese}-${QRCodeItem.AreaChinese}`)"
                            >
                                下載
                            </button>
                            <button
                                ref="copyButton"
                                class="btn btn-info mr-2"
                                data-clipboard-target="#copyText"
                                @click="initClipboard"
                            >
                                <span style="color: #fff">
                                {{ i18n("Basic.CopyLink") }}
                                </span>
                            </button>
                            <button
                                type="reset"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                {{ i18n("Buttons.Cancel") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
/* global $ */
import { config } from "@/components/table_config.js";
import { mapGetters, mapActions } from "vuex";
import { apiGetBulidingAll, apiGetBulidingPages, apiAddBuliding, apiEditBuliding, apiGetAreaPages, apiAddArea, apiEditArea, apiGetRestaurantSeatNoPages, apiAddRestaurantSeatNo, apiEditRestaurantSeatNo, apiGetRestaurantCnrDesign, apiSaveRestaurantCnrDesign } from '@/apis/restaurantManage'

import QrcodeVue from "qrcode.vue";
import Clipboard from "clipboard";
// import CommonModal from '@/components/Modal/commonModal.vue'

export default {

  components: {
    QrcodeVue
  },

  data() {
    return {

      // Modal狀態
      isAdd: false,

      // 判斷角色名稱是否重複
      item: {
          BuildingGuid: null
          },
      nowItem: {},

      restaurantInfo: {
        TitleChinese: null,
        TitleEnglish: null,
        DescriptionChinese: null,
        DescriptionEnglish: null,
        CompletedChinese: null,
        CompletedEnglish:null
      },
      
      buildingKeyword: null,
      areaKeyword: null,
      seatKeyword: null,

      buildingRows: [],
      buildingColumns: [
        {
          label: "棟別(中文)",
          name: "BuildingChinese",
        },
        {
          label: "棟別(英文)",
          name: "BuildingEnglish",
        },
        {
          label: "",
          slot_name: "edit",
        },
        {
          label: "",
          slot_name: "delete",
        },
      ],
      buildingTotal: 0,
      areaRows: [],
      areaColumns: [
        {
          label: "棟別",
          name: "BuildingChinese",
        },
        {
          label: "區域(中文)",
          name: "AreaChinese",
        },
        {
          label: "區域(英文)",
          name: "AreaEnglish",
        },
        {
          label: "",
          slot_name: "edit",
        },
        {
          label: "",
          slot_name: "qrcode",
        },
        {
          label: "",
          slot_name: "delete",
        },
      ],
      areaTotal: 0,
      seatRows: [],
      seatColumns: [
        {
          label: "棟別",
          name: "BuildingChinese",
        },
        {
          label: "區域",
          name: "AreaChinese",
        },
        {
          label: "座位號碼",
          name: "SeatNo",
        },
        {
          label: "",
          slot_name: "edit",
        },
        {
          label: "",
          slot_name: "delete",
        },
      ],
      seatTotal: 0,

      config: {
          ...config,
          server_mode: true,
      },
      classes: {
          tableWrapper: "table-responsive footprint-table",
          cell: {
              "text-left" : true
          },
      },
      buildingTableInfo: {  //棟別 分頁資訊
          // 分頁資訊
          page: 1,
          per_page: 10,
      },
      areaTableInfo: {  //區域 分頁資訊
          // 分頁資訊
          page: 1,
          per_page: 10,
      },
      seatTableInfo: {  //座位號碼 分頁資訊
          // 分頁資訊
          page: 1,
          per_page: 10,
      },

      buildingOptions: [],
      areaOptions: [],

      QRcode: '',
      QRCodeItem: {},
      size: 300,

      clipboard: null,
    };
  },

  computed: {
    ...mapGetters(["lang"]),
  },

  watch: {
    lang: function() {

    },
    "item.BuildingGuid": function() {
        if(Object.keys(this.item).length !== 0 && this.item.constructor === Object) {
            this.getAreaAllFromBuilding()
        }
        
    },

  },

  methods: {
    ...mapActions(['updateLoading']),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    //  新增&編輯&刪除 Modal
    commonModal(isAdd, item) {
      if (!isAdd) {
        this.item = { ...item };
        // 原data
        this.nowItem = {
          ...item,
        };
        this.isAdd = false;
      } else {
        // this.item = {BuildingGuid: null};
         this.item = {};
        this.isAdd = true;
      }
      this.$refs.building.reset();
      this.$refs.area.reset();
      this.$refs.seat.reset();
    },

    // 顯示 QRcode
    showQrcode(item) {
        const url = window.location.origin;
        
        this.QRcode = `${url}/#/restaurant/${item.Guid}`;
        this.QRCodeItem = item
    },

    //  複製套件 初始化 (避免 success.length > 0)
    initClipboard() {
        //成功
        this.clipboard.on("success", () => {
            this.$notify({
                title: "成功",
                message: "成功複製連結",
                type: "success",
                duration: 1500,
            });

        });
        setTimeout(() => {
            this.clipboard.destroy();
            this.clipboard = new Clipboard(this.$refs.copyButton);
        }, 100)
    },

    onChangeBuliding(queryParams) {
        const vm = this;
        console.log('queryParams', queryParams)
        // console.log(JSON.parse(JSON.stringify(queryParams)));

        vm.buildingTableInfo = {
            page: queryParams.page,
            per_page: queryParams.per_page,
        };
        vm.getBulidingPages(queryParams.page,  queryParams.per_page)

    },
    onChangeArea(queryParams) {
        const vm = this;
        console.log('queryParams', queryParams)
        // console.log(JSON.parse(JSON.stringify(queryParams)));

        vm.areaTableInfo = {
            page: queryParams.page,
            per_page: queryParams.per_page,
        };
        vm.getAreaPages(queryParams.page,  queryParams.per_page)

    },
    onChangeSeat(queryParams) {
        const vm = this;
        console.log('queryParams', queryParams)
        // console.log(JSON.parse(JSON.stringify(queryParams)));

        vm.seatTableInfo = {
            page: queryParams.page,
            per_page: queryParams.per_page,
        };
        vm.getRestaurantSeatNoPages(queryParams.page,  queryParams.per_page)

    },

    //  取得全部棟別
    getBulidingAll() {
        apiGetBulidingAll()
        .then((response) => {
            // console.log(response)

            if(response.status === 200) {
                this.buildingOptions = response.data
            }
        })
    },

    //  取得棟別
    getBulidingPages(page = 1, per_page = 10) {

        this.updateLoading(true)
        apiGetBulidingPages({
            Keyword: this.buildingKeyword,
            Page: page,
            PerPage: per_page,
        }).then((response) => {
            // console.log(response)

            if(response.status === 200) {
                this.buildingRows = response.data.Dtos
                this.buildingTotal = response.data.DataQuantity

                this.updateLoading(false)
            }
        })
    },

    //  新增棟別
    addBuliding() {

      this.updateLoading(true)
      apiAddBuliding({
        ...this.item
      }).then((response) => {
          console.log(response)

          if(response.status === 200) {
            this.item = {};
            $("#buildingModal").modal("hide");

            this.getBulidingPages(
                this.buildingTableInfo.page,
                this.buildingTableInfo.per_page,
            );

            this.$notify({
                title: "成功",
                message: "成功新增棟別",
                type: "success",
                duration: 3500,
            });

            this.getBulidingAll();
          }

          this.updateLoading(false)
      })
    },

    //  編輯棟別
    editBuliding() {

      this.updateLoading(true)
      apiEditBuliding({
        ...this.item
      }).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.item = {};
                $("#buildingModal").modal("hide");

                this.getBulidingPages(
                    this.buildingTableInfo.page,
                    this.buildingTableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功編輯棟別",
                    type: "success",
                    duration: 3500,
                });

                this.getBulidingAll();
            }

            this.updateLoading(false)
      })
    },

    //  刪除棟別
    delBuliding() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Questionnire/DeleteBuliding/${vm.item.Guid}`;

      this.updateLoading(true)
      
      vm.$http.delete(api).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.item = {};
                $("#delBuildingModal").modal("hide");

                this.getBulidingPages(
                    this.buildingTableInfo.page,
                    this.buildingTableInfo.per_page,
                );

                this.getAreaPages(
                    this.areaTableInfo.page,
                    this.areaTableInfo.per_page,
                );

                this.getRestaurantSeatNoPages(
                    this.seatTableInfo.page,
                    this.seatTableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功刪除棟別",
                    type: "success",
                    duration: 3500,
                });

                this.getBulidingAll();
            }

            this.updateLoading(false)
      })
      .catch(() => {
        this.updateLoading(false)
      })
    },

    //  儲存棟別
    saveBuilding() {
        if(this.isAdd) {
            this.addBuliding()
        } else {
            this.editBuliding()
        }
    },

    //  取得區域
    getAreaPages(page = 1, per_page = 10) {

        this.updateLoading(true)
        apiGetAreaPages({
            Keyword: this.areaKeyword,
            Page: page,
            PerPage: per_page,
        }).then((response) => {
            // console.log(response)

            if(response.status === 200) {
                this.areaRows = response.data.Dtos
                this.areaTotal = response.data.DataQuantity

                this.updateLoading(false)
            }
        })
    },

    //  取得哪棟底下的區域
    getAreaAllFromBuilding() {
        const vm = this;
        const api = `${window.BaseUrl.api}/Questionnire/GetAreaAllFromBuilding/${vm.item.BuildingGuid}`

        this.updateLoading(true)
      
        vm.$http.get(api).then((response) => {
                console.log(response)

                if(response.status === 200) {

                    this.areaOptions = response.data
                }

                this.updateLoading(false)
        })
        .catch(() => {
            this.updateLoading(false)
        })
    },

    //  新增區域
    addArea() {

      this.updateLoading(true)
      apiAddArea({
        ...this.item
      }).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.item = {};
                $("#areaModal").modal("hide");

                this.getAreaPages(
                    this.areaTableInfo.page,
                    this.areaTableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功新增區域",
                    type: "success",
                    duration: 3500,
                });
            }

            this.updateLoading(false)
      })
    },

    //  編輯區域
    editArea() {

      this.updateLoading(true)
      apiEditArea({
        ...this.item
      }).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.item = {};
                $("#areaModal").modal("hide");

                this.getAreaPages(
                    this.areaTableInfo.page,
                    this.areaTableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功編輯區域",
                    type: "success",
                    duration: 3500,
                });
            }

            this.updateLoading(false)
      })
    },

    //  刪除區域
    delArea() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Questionnire/DeleteArea/${vm.item.Guid}`;

      this.updateLoading(true)
      
      vm.$http.delete(api).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.item = {};
                $("#delAreaModal").modal("hide");

                this.getAreaPages(
                    this.areaTableInfo.page,
                    this.areaTableInfo.per_page,
                );

                this.getRestaurantSeatNoPages(
                    this.seatTableInfo.page,
                    this.seatTableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功刪除區域",
                    type: "success",
                    duration: 3500,
                });
            }

            this.updateLoading(false)
      })
      .catch(() => {
        this.updateLoading(false)
      })
    },

    //  儲存區域
    saveArea() {
        if(this.isAdd) {
            this.addArea()
        } else {
            this.editArea()
        }
    },

    //  取得座位號碼
    getRestaurantSeatNoPages(page = 1, per_page = 10) {

        this.updateLoading(true)
        apiGetRestaurantSeatNoPages({
            Keyword: this.seatKeyword,
            Page: page,
            PerPage: per_page,
        }).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.seatRows = response.data.Dtos
                this.seatTotal = response.data.DataQuantity

                this.updateLoading(false)
            }
        })
    },

    //  新增座位號碼
    addRestaurantSeatNo() {

      this.updateLoading(true)
      apiAddRestaurantSeatNo({
        ...this.item
      }).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.item = {};
                $("#seatModal").modal("hide");

                this.getRestaurantSeatNoPages(
                    this.seatTableInfo.page,
                    this.seatTableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功新增座位號碼",
                    type: "success",
                    duration: 3500,
                });
            }

            this.updateLoading(false)
      })
    },

    //  編輯座位號碼
    editRestaurantSeatNo() {

      this.updateLoading(true)
      apiEditRestaurantSeatNo({
        ...this.item
      }).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.item = {};
                $("#seatModal").modal("hide");

                this.getRestaurantSeatNoPages(
                    this.seatTableInfo.page,
                    this.seatTableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功編輯座位號碼",
                    type: "success",
                    duration: 3500,
                });
            }

            this.updateLoading(false)
      })
    },

    //  刪除座位號碼
    delSeat() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Questionnire/DeleteRestaurantSeatNo/${vm.item.Guid}`;

      this.updateLoading(true)
      
      vm.$http.delete(api).then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.item = {};
                $("#delSeatModal").modal("hide");

                this.getRestaurantSeatNoPages(
                    this.seatTableInfo.page,
                    this.seatTableInfo.per_page,
                );

                this.$notify({
                    title: "成功",
                    message: "成功刪除座位號碼",
                    type: "success",
                    duration: 3500,
                });
            }

            this.updateLoading(false)
      })
      .catch(() => {
        this.updateLoading(false)
      })
    },

    //  儲存座位號碼
    saveSeat() {
        if(this.isAdd) {
            this.addRestaurantSeatNo()
        } else {
            this.editRestaurantSeatNo()
        }
    },

    // 最新餐廳實名制問卷設計
    getRestaurantCnrDesign() {
        
        this.updateLoading(true)
        apiGetRestaurantCnrDesign()
        .then((response) => {
            // console.log(response)

            if(response.status === 200) {
                this.restaurantInfo = response.data

                this.updateLoading(false)
            }
        })
    },

    //  儲存餐廳實名制問卷設計
    saveRestaurantCnrDesign() {

        this.updateLoading(true)
        apiSaveRestaurantCnrDesign({
            ...this.restaurantInfo
        })
        .then((response) => {
            console.log(response)

            if(response.status === 200) {
                this.$notify({
                    title: "成功",
                    message: "成功儲存",
                    type: "success",
                    duration: 3500,
                });

                this.updateLoading(false)
                this.getRestaurantCnrDesign();
            }
        })
    },
    
    downloadQRCode(filename) {  //  下載QRCode
        let canvas = document.getElementsByTagName('canvas')

        let a = document.createElement('a')
        a.href = canvas[0].toDataURL();
        a.download = filename || 'QRCode.png'

        a.click()

    },

  },

  mounted() {
      this.getRestaurantCnrDesign()
      this.getBulidingAll()

      this.getBulidingPages()
      this.getAreaPages()
      this.getRestaurantSeatNoPages()

      this.clipboard = new Clipboard(this.$refs.copyButton);
  },

  destroyed() {
    $(".modal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    this.$notify.close();
  },
};
</script>



<style lang="scss" scoped>
.search-card {
    margin-bottom: 0;

    .card-body {
        padding: 1.25rem;
        padding-bottom: 0;
    }
}

.yahome-table-wrap {
    padding: 0 20px !important;
    border-radius: 0px;
}

.block {
    padding: 20px;
    padding-bottom: 0;
    background-color: #fff;
}

.row .form-group label {
    font-size: 0.8em;
}

#qrcodeModal {
    .modal-content {
        background-color: rgba(0, 0, 0, 0.6);
    }

    .modal-body {
        text-align: center;

        .qrcode-wrap {
            display: inline-block;
            background-color: #fff;
            padding: 10px;
            border-radius: 5px;
        }
    }
}
</style>
